import React, { useState } from 'react';
import axios from 'axios';

function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const apiUrl = process.env.REACT_APP_API_URL; // Get the base URL from environment variables

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Clear previous message
    setMessage(null);
    // Start loading
    setLoading(true);
    try {
      // Make API request
      const response = await axios.post(`${apiUrl}/api/v1/users/forgot-password`, {
        email,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      

      // Success message
      if (response.status === 200) {
        setMessage('Password reset email has been sent if the account exists.');
      }
    } catch (error: any) {
      // Handle errors
      if (error.response) {
        if (error.response.status === 404) {
          setMessage('No account found with this email.');
        } else if (error.response.status === 400) {
          setMessage('Invalid email address.');
        } else {
          setMessage('An unexpected error occurred. Please try again.');
        }
      } else {
        setMessage('Unable to connect to the server. Please try again later.');
      }
    } finally {
      // Stop loading
      setLoading(false);
    }
  };

  return (
    <div className="App flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-1/3 bg-white text-gray-900 p-6 rounded-md shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Forgot Password</h2>
        <p className="mb-6 text-center text-gray-700">
          Enter the email address associated with your account. If there is an account that matches,
          we will email the information to reset your password.
        </p>
        <form onSubmit={handleSubmit}>
          <label className="block font-bold text-gray-700 mb-2">Account Email</label>
          <input
            type="email"
            placeholder="Enter your email"
            className="p-3 rounded-md w-full border border-gray-300 text-gray-700 mb-4"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md w-full"
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Email'}
          </button>
        </form>
        {message && (
          <div className={`mt-4 text-center ${message.includes('error') ? 'text-red-500' : 'text-green-500'}`}>
            {message}
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;