import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { EmailLoginPage, PasswordLoginPage } from './LandingPage';
import EngPage from './EngPage';
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import Project from './Project';
import Specification from './Specification';
import './App.css';

function App() {
  return (
      <Router>
        <Routes>
          {/* Landing Page Route */}
          <Route path="/" element={<EmailLoginPage />} />
          <Route path="/password-login" element={<PasswordLoginPage />} />
          {/* Project Pages Routes */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/specification" element={<Specification />} />
          <Route path="/register" element={<Register />} />
          <Route path="/project" element={<Project />} />
          <Route path="/eng" element={<EngPage />} />
        </Routes>
      </Router>
  );
}

export default App;