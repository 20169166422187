import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaLock } from 'react-icons/fa';
import axios from 'axios';

function LandingPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL; // Get the base URL from environment variables

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent the default form submission
    setError(''); // Clear any existing error messages

    try {
      const response = await axios.post(`${apiUrl}/api/v1/users/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        console.log('Login successful:', response.data.message);
        navigate('/specification'); // Navigate to the project page
      }
    } catch (err: any) {
      if (err.response) {
        // Handle API error responses
        setError(err.response.data.message || 'Invalid email or password.');
      } else {
        setError('An error occurred. Please try again.');
      }
      console.error('Login error:', err);
    }
  };

  return (
    <div className="App flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-200">
      {/* Central Rectangular Box */}
      <aside className="w-1/3 bg-white text-gray-900 h-2/3 p-6 flex flex-col justify-center rounded-md shadow-lg">
        <div className="mb-6">
          <h2 className="cbase-title font-bold text-2xl mb-10 text-center flex items-center justify-center">
            cbase ai
          </h2>
          <form onSubmit={handleLogin} className="flex flex-col items-center w-full">
            {/* Email Input */}
            <label className="w-full text-left mb-2 text-gray-700 font-bold">
              Email<span className="text-red-500">*</span>
            </label>
            <div className="flex items-center w-full mb-4">
              <FaUser className="text-gray-500 mr-2" />
              <input
                type="email"
                placeholder="Enter your email"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {/* Password Input */}
            <label className="w-full text-left mb-2 text-gray-700 font-bold">
              Password<span className="text-red-500">*</span>
            </label>
            <div className="flex items-center w-full mb-4">
              <FaLock className="text-gray-500 mr-2" />
              <input
                type="password"
                placeholder="Enter your password here"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {/* Error Message */}
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            {/* Login Button */}
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md w-full mb-4"
            >
              Log In
            </button>
          </form>
          {/* Forgot Password Link */}
          <Link
            to="/forgot-password"
            className="text-blue-500 hover:text-blue-600 font-bold w-full text-center mb-2 block"
          >
            Forgot Password
          </Link>
          {/* Create New Account Link */}
          <Link
            to="/register"
            className="bg-gray-100 text-gray-700 font-bold py-2 px-4 rounded-md w-full text-center border border-gray-300 block"
          >
            Create New Account
          </Link>
        </div>
      </aside>
    </div>
  );
}

export default LandingPage;