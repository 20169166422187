import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Register() {
  const [companyName, setCompanyName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL; // Base URL from environment variables

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (!agreedToTerms) {
      setError('You must agree to the terms of service.');
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/api/v1/users/register`, {
        companyName,
        email,
        firstName,
        lastName,
        password,
      });

      if (response.status === 201) {
        setSuccess('Account created successfully!');
        navigate('/');
        
      }
    } catch (err: any) {
      if (err.response) {
        setError(err.response.data.message || 'Registration failed.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="App flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-2/3 bg-white text-gray-900 p-8 rounded-md shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Create New Account</h2>
        <p className="mb-6 text-left text-gray-700">
          Please provide the following information to create your account.
        </p>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {success && <p className="text-green-500 text-center mb-4">{success}</p>}
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block font-bold text-gray-700 mb-1 text-left">
                Company Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Company Name Here"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block font-bold text-gray-700 mb-1 text-left">
                Email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                placeholder="name@company.com"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block font-bold text-gray-700 mb-1 text-left">
                First Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="First Name"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block font-bold text-gray-700 mb-1 text-left">
                Last Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Last Name"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>
          <p className="mb-4 text-gray-700 text-left">
            Create a new password for your account.
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-700 text-left">
            <li>Special characters (!@#$%^&*)</li>
            <li>Lowercase (a-z), uppercase (A-Z), and numbers (0-9)</li>
            <li>At least 8 characters (we recommend 12)</li>
            <li>A maximum of 35 characters</li>
          </ul>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block font-bold text-gray-700 mb-1 text-left">
                Create Password<span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                placeholder="Password"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block font-bold text-gray-700 mb-1 text-left">
                Confirm Password<span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                placeholder="Confirm Password"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              className="mr-2"
              checked={agreedToTerms}
              onChange={(e) => setAgreedToTerms(e.target.checked)}
            />
            <span className="text-gray-700">
              By using this application you are agreeing to our{' '}
              <Link to="/terms" className="text-blue-500 hover:text-blue-600">
                Terms of Service
              </Link>
            </span>
          </div>
          <div className="flex justify-between items-center">
            <Link to="/" className="text-blue-500 hover:text-blue-600">
              Go Back to Login
            </Link>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
              // disabled={!agreedToTerms}
            >
              Create My Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;