import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Button, Avatar, Spin, message, Badge,Tooltip  } from 'antd';
import {
  BellOutlined,
  SettingOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import './TopMenu.css';

interface Project {
  key: string;
  projectName: string;
}

interface TopMenuProps {
  projects: Project[]; // Renamed to `initialProjects` to avoid conflict
  selectedProjectName?: string;
  setProjectId?: (id: string) => void;
}

const TopMenu: React.FC<TopMenuProps> = ({ projects, selectedProjectName, setProjectId }) => {
  const [recentProjects, setRecentProjects] = useState<Project[]>([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Replace with actual API URL
  const navigate = useNavigate(); // Initialize navigate function

  // Fetch projects from the API
  const fetchProjects = async () => {
    if (loadingProjects) return;
    setLoadingProjects(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/projects`);
      const allProjects = response.data.projects || [];
      const formattedProjects = allProjects.map((project: any) => ({
        key: project.id,
        projectName: project.name,
      }));
      setRecentProjects(formattedProjects.slice(0, 5)); // Use the first 5 as recent projects
    } catch (error) {
      console.error('Error fetching projects:', error);
      message.error('Failed to load projects.');
    } finally {
      setLoadingProjects(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleProjectSelect = (projectKey: string) => {
    navigate(`/specification?projectId=${projectKey}`); // Navigate to the Specification page with the project ID
  };

  const handleViewAllProjects = () => {
    navigate('/project'); // Navigate to the Projects page
  };

  const handleCreateProject = () => {
    navigate('/project?action=create'); // Navigate with query parameter
  };

  const handleLogout = () => {
    navigate('/'); // Navigate to the login screen
  };

  const projectMenu = (
    <Menu>
      <Menu.ItemGroup title="Recent">
        {recentProjects.map((project) => (
          <Menu.Item key={project.key} onClick={() => handleProjectSelect(project.key)}>
            <div className="project-item">
              {/* <Avatar size="small" className="project-avatar"> */}
                {/* {project.projectName[0].toUpperCase()} */}
              {/* </Avatar> */}
              <span className="project-name">{project.projectName}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item key="view-all" onClick={handleViewAllProjects}>
        View All Projects
      </Menu.Item>
      <Menu.Item key="create" onClick={handleCreateProject}>
        <PlusOutlined /> Create Project
      </Menu.Item>
    </Menu>
  );
  const profileMenu = (
    <Menu>
      {/* <Menu.Item key="profile" onClick={() => navigate('/profile')}>
        Profile
      </Menu.Item> */}
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="top-menu">
      <div className="app-frame-header">
        <div className="logo">
          <img src="/cspec_logo_1.jpg" alt="CSpec Logo" className="logo-icon" />
          <span className="app-name">CSpec</span>
        </div>
        {/* <Button type="link" className="home-link">
          Home
        </Button> */}
        <Dropdown overlay={projectMenu} trigger={['click']} disabled={loadingProjects}>
          <Button className="projects-dropdown">
            {loadingProjects ? <Spin size="small" /> : selectedProjectName || 'Projects'}{' '}
            <DownOutlined />
          </Button>
        </Dropdown>
        <div className="spacer"></div>
        <div className="right-icons">
        <Dropdown trigger={['click']}>
        <Tooltip title="Coming soon">
            <QuestionCircleOutlined className="icon" />
          </Tooltip>
          </Dropdown>
          <div className="notification-icon">
          <Tooltip title="Coming soon">
            <Badge count={5} showZero className="notification-count">
              <BellOutlined className="icon" />
            </Badge>
          </Tooltip>
          </div>
          <Tooltip title="Coming soon">
          <SettingOutlined className="icon" />
        </Tooltip>
          <Dropdown overlay={profileMenu} trigger={['click']}>
            <Avatar className="user-avatar" icon={<UserOutlined />} />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;