import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaUser, FaLock, FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';

function EmailLoginPage() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleNext = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      navigate('/password-login', { state: { email } });
    }
  };

  return (
    <div className="App flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-200">
      <aside className="w-1/3 bg-white text-gray-900 h-2/3 p-6 flex flex-col justify-center rounded-md shadow-lg">
        <div className="mb-6">
          <h2 className="cbase-title font-bold text-2xl mb-10 text-center flex items-center justify-center">
            cbase ai
          </h2>
          <form onSubmit={handleNext} className="flex flex-col items-center w-full">
            <label className="w-full text-left mb-2 text-gray-700 font-bold">
              Email<span className="text-red-500">*</span>
            </label>
            <div className="flex items-center w-full mb-4">
              <FaUser className="text-gray-500 mr-2" />
              <input
                type="email"
                placeholder="Enter your email"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md w-full mb-4"
            >
              Next
            </button>
          </form>
          <button
            className="bg-gray-100 text-gray-400 font-bold py-2 px-4 rounded-md w-full text-center border border-gray-300 block cursor-not-allowed"
            disabled
          >
            Create New Account
          </button>
        </div>
      </aside>
    </div>
  );
}

function PasswordLoginPage() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL;

  const email = location.state?.email || '';

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${apiUrl}/api/v1/users/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        console.log('Login successful:', response.data.message);
        navigate('/project');
      }
    } catch (err: any) {
      if (err.response) {
        setError(err.response.data.message || 'Invalid email or password.');
      } else {
        setError('An error occurred. Please try again.');
      }
      console.error('Login error:', err);
    }
  };

  return (
    <div className="App flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-200">
      <aside className="w-1/3 bg-white text-gray-900 h-2/3 p-6 flex flex-col justify-center rounded-md shadow-lg relative">
        
        {/* "cbase ai" Logo (Now added to Password Login Page) */}
        <h2 className="cbase-title font-bold text-2xl mb-6 text-center text-orange-500">
          cbase ai
        </h2>

        {/* Back Button and Email in Flex Container for Proper Alignment */}
        <div className="flex items-center mb-6">
          <button
            className="text-gray-600 hover:text-gray-700 flex items-center mr-3 font-normal"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft size={20} />
          </button>
          <h3 className="text-sm text-gray-700 font-normal">{email}</h3>
        </div>
          <form onSubmit={handleLogin} className="flex flex-col items-center w-full">
            <label className="w-full text-left mb-2 text-gray-700 font-bold">
              Enter password<span className="text-red-500">*</span>
            </label>
            <div className="flex items-center w-full mb-4">
              <FaLock className="text-gray-500 mr-2" />
              <input
                type="password"
                placeholder="Enter your password here"
                className="p-3 rounded-md w-full border border-gray-300 text-gray-700"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md w-full mb-4"
            >
              Log In
            </button>
          </form>
          <Link
            to="/forgot-password"
            className="text-blue-500 hover:text-blue-600 font-bold w-full text-center mb-2 block"
          >
            Forgot my password
          </Link>
      </aside>
    </div>
  );
}

export { EmailLoginPage, PasswordLoginPage };